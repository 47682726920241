<template>
  <div class="new">
    <div class="new-banner">
      <img :src="require('@imgs/new/banner.png')" v-if="$isMobile()" />
      <img :src="require('@imgs/new/web/banner.png')" v-else>
      <div class="new-banner-title">
        <h3>新闻中心</h3>
        <span>NEWS AND INFORMATION</span>
      </div>
    </div>
    <div class="new-breadcrumb">
      当前位置:<span @click="$router.push({ path: '/' })">首页</span> -> <span
        @click="$router.push({ path: '/new' })">新闻资讯</span>
      -> 详情
    </div>
    <div class="new-detail">
      <div class="new-detail-title" :style="$isMobile() ? 'padding:0 12px;' : ''">
        {{ info.title }}
      </div>
      <div class="new-detail-info">
        <span>作者:虚拟现实教育工作委员会</span> <span>发布日期:{{ info.createTime }}</span> <span>浏览次数:{{ info.browseCount }}</span>
      </div>
      <div class="new-detail-content ql-editor" v-html="info.content" :style="$isMobile() ? 'padding:15px 20px;' : ''">

      </div>
      <div class="new-detail-but" @click="$router.back()">
        返回列表
      </div>
    </div>
  </div>
</template>
<script>
import newContent from './new-content'
export default {
  name: 'newDetail',
  components: {
    newContent
  },
  data() {
    return {
      info: {}
    }
  },
  computed: {
    newTabItem() {
      return this.$route.query.type === '1' ? '工委会要闻' : '行业资讯'
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      let type = this.$route.query.type
      let id = this.$route.query.id
      this.$http({
        // url: type === '1' ? `/special_committee_news/${id}` : `/news/${id}`
        url: `/special_committee_news/${id}`
      }).then(res => {
        this.info = res.data
      })
    }
  }
}
</script>
<style scoped lang="scss">
.new {
  position: relative;

  &-banner {
    width: 100%;
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    &-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      text-align: center;

      h3 {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.8;
        font-family: Microsoft YaHei;
      }

      span {
        font-size: 1em;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        width: 400px;
        display: inline-block;
        font-family: Microsoft YaHei;
      }
    }
  }

  &-breadcrumb {
    max-width: 1100px;
    margin: 0 auto;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: left;
    cursor: pointer;
    font-family: Microsoft YaHei;
  }

  &-detail {
    max-width: 1000px;
    margin: 0 auto;
    background: #fff;

    &-info {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      text-align: center;
      padding: 14px 12px 40px 12px;
      border-bottom: 1px dotted #979797;
      padding-bottom: 30px;
      font-family: Microsoft YaHei;

      span {
        display: inline-block;
        margin-right: 10px;
      }
    }

    &-title {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      padding: 30px 50px 0;
      font-family: Microsoft YaHei;
    }

    &-but {
      padding: 12px 0;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
      font-family: Microsoft YaHei;
    }

    &-content {
      max-width: 1000px;
      margin: 0 auto;
    }
  }
}
</style>